const serviceInfo = {
  title: "Services",
  sections: [
    {
      header: "Architecture",
      imgSrc: "img/Our Services/architecture.jpg",
      content:
        "At WY Ong Architect, we use an integrated approach to design buildings to ensure it is sustainable, user friendly and able to adapt to the site. In every project, we always look for the client's needs and provide solutions to solve their problems and enhance their expectations. WY Ong Architect specializes in various categories of projects such as:",
      bullets: [
        "Refurbishment",
        "Residentials (Bungalow, Low & High Rise)",
        "Commercial",
        "Industrial",
        "Mixed use Development",
      ],
    },
    {
      header: "Master Planning",
      imgSrc: "img/Our Services/master planning.jpg",
      content:
        "The social, economic value and quality of living of a place is very dependent on the planning of land. We believe designing an urban space must be sensitive towards the environment, function, culture and economic activities. Thus, the place can sustain itself and grow well.",
      bullets: [],
    },
    {
      header: "Interior Design",
      imgSrc: "img/Our Services/interior.jpg",
      content:
        "In the climate of Malaysia, we always spend most of our time indoors. The quality of interior space can affect the emotional and experience of the users. We believe designing a space which is functional, aesthetic and green is able to achieve a comfortable space for our clients.",
      bullets: [],
    },
  ],
};

const serviceInfo2 = {
  title: "Services",
  sections: [
    {
      header: "Green Building Index (GBI) Consultation",
      imgSrc: "img/Our Services/gbi.jpg",
      content:
        "Elevate your project's sustainability with our Green Building Index Consultation services. Our experts focus on eco-friendly design, ensuring your building harmonizes with the environment, culture, and economy, fostering sustainability and growth.",
      bullets: [],
    },
  ],
};

export const Services = () => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="row">
          <div className="servicesPageTitle"> {serviceInfo.title}</div>
          <div
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            {serviceInfo.sections.map((info, index) => {
              return (
                <div className="col-lg-4 col-md col-sm col-xs services-details-container">
                  <div className="servicesHeader">{info.header}</div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img className="servicesImg" src={info.imgSrc} alt="" />
                  </div>
                  <div className="servicesContent">{info.content}</div>
                  <div className="servicesContent">
                    <ul>
                      {info.bullets.map((bullet, index) => {
                        return <li>{bullet}</li>;
                      })}
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {serviceInfo2.sections.map((info, index) => {
              return (
                <div className="col-lg-4 col-md col-sm col-xs services-details-container">
                  <div className="servicesHeader">{info.header}</div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img className="servicesImg" src={info.imgSrc} alt="" />
                  </div>
                  <div className="servicesContent">{info.content}</div>
                  <div className="servicesContent">
                    <ul>
                      {info.bullets.map((bullet, index) => {
                        return <li>{bullet}</li>;
                      })}
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
