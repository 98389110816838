import { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import { Link, useParams } from "react-router-dom";
import "../css/carousel.min.css"; // requires a loader
import Data from "../data/data.json";

export const ProjectDetails = () => {
  const { id } = useParams();

  useEffect(() => {
    const lightbox = document.createElement("div");
    lightbox.id = "lightbox";
    lightbox.className = "lightbox";
    document.body.appendChild(lightbox);

    const images = document.querySelectorAll(".carousel-root img");
    images.forEach((image) => {
      image.addEventListener("click", (e) => {
        lightbox.classList.add("active");
        const img = document.createElement("img");
        img.src = image.src;
        while (lightbox.firstChild) {
          lightbox.removeChild(lightbox.firstChild);
        }
        lightbox.appendChild(img);
      });
    });

    lightbox.addEventListener("click", (e) => {
      if (e.target !== e.currentTarget) return;
      lightbox.classList.remove("active");
    });
  });

  const currentProject = Data.filter((x) => x.title === id); //console.log(currentImg);

  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="row" style={{ textAlign: "left" }}>
          <Carousel
            showStatus={false}
            infiniteLoop
            dynamicHeight={true}
            showThumbs={false}
            autoPlay
            //className="home-carousel"
            //autoPlay

            showArrows={true}
            showIndicators={false}
          >
            {currentProject[0].slideshow.map((slideshow, index) => (
              <div
                onClick={() => {
                  document.getElementById(`${index}`).click();
                }}
              >
                <img
                  key={index}
                  src={`/${slideshow.img}`}
                  style={{
                    width: slideshow.width === null ? "100%" : slideshow.width,
                  }}
                  //className="img-responsive"
                  alt="Project Title"
                  id={index}
                />
                <p className="legend">
                  {slideshow.description_title !== "" && (
                    <div> {slideshow.description_title}</div>
                  )}
                  {slideshow.description}
                </p>
              </div>
            ))}
          </Carousel>
          <div className="col-xs-12 col-md-4 projectDetails_description_container">
            <div className="projectDetailsDescription_title">Project Name:</div>
            <div className="projectDetailsDescription">
              {currentProject[0].title}
            </div>
            <div className="projectDetailsDescription_title">Location:</div>
            <div className="projectDetailsDescription">
              {currentProject[0].location}
            </div>
            <div className="projectDetailsDescription_title">Type:</div>
            <div className="projectDetailsDescription">
              {currentProject[0].type === ""
                ? currentProject[0].coverCategory
                : currentProject[0].type}
            </div>
            {currentProject[0].status && (
              <>
                <div className="projectDetailsDescription_title">Status:</div>
                <div className="projectDetailsDescription">
                  {currentProject[0].status}
                </div>
              </>
            )}
          </div>
          <div className="col-xs-12 col-md-8 projectDetails_description_container">
            {currentProject[0].description.map((data) => {
              return <div className="projectDetailsDescription">{data}</div>;
            })}
          </div>
        </div>

        <div
          style={{
            paddingBlock: "25px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link
            className="more-button"
            style={{
              borderRadius: "5px",
              position: "relative",
              width: "150px",
              display: "block",
              paddingBlock: "10px",
            }}
            to="/projects"
          >
            <span style={{ color: "white", fontFamily: "Futuna" }}>
              More Projects
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};
