import { useEffect, memo } from "react";

const Contact = () => {
  useEffect(() => {
    console.log("Recreate");
  }, []);

  return (
    <div>
      <div id="contact" className="text-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md col-sm col-xs">
              <iframe
                title="location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1408.5543915037179!2d101.44473643130559!3d3.09640059033278!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc53f29c655025%3A0x4d77ce1bc5dd8cc7!2s8%2C%20Jalan%20Setia%20Prima%20G%20U13%2FG%2C%20Setia%20Alam%2C%2040170%20Shah%20Alam%2C%20Selangor!5e0!3m2!1sen!2smy!4v1655799483266!5m2!1sen!2smy"
                width="550"
                height="450"
                style={{ border: 0, paddingRight: "35px" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="googlemap"
              ></iframe>
            </div>
            <div className="col-lg-6 col-md col-sm col-xs contact-us-container">
              <div className="contact-title">Contact Us</div>
              <div className="contact-container">
                <div className="contact-header">WY Ong Architect (SP/O 90)</div>
                <div className="contact-content">
                  8-3-1, Jalan Setia Prima (G) U13/G,
                  <br /> Setia Alam, 40170 Shah Alam,
                  <br /> Selangor
                </div>
              </div>

              <div className="contact-container">
                <div className="contact-us-info" style={{ marginLeft: "0px" }}>
                  <div style={{ color: "grey", fontWeight: "500" }}>
                    <i className="fa-solid fa-phone fa-lg"></i>
                  </div>
                  <div
                    className="info-details"
                    style={{ color: "grey", margin: "0 0 0 11px" }}
                  >
                    016-9002688
                  </div>
                </div>
                <div className="info-details">
                  <a
                    className="contact-us-info"
                    href="https://www.instagram.com/wyong.architect/"
                  >
                    <i
                      style={{ color: "grey" }}
                      className="fa fa-instagram footer-fa fa-lg"
                    ></i>
                    <div style={{ color: "grey", margin: "0 0 0 12px" }}>
                      https://www.instagram.com/wyong.architect/
                    </div>
                  </a>
                </div>
                <div className="info-details">
                  <a
                    className="contact-us-info"
                    href="https://www.facebook.com/wyong.architect"
                  >
                    <i
                      style={{ color: "grey" }}
                      className="fa fa-facebook footer-fa fa-lg"
                    ></i>
                    <div style={{ color: "grey", margin: "0 0 0 15px" }}>
                      https://www.facebook.com/wyong.architect
                    </div>
                  </a>
                </div>
                <div className="info-details">
                  <a
                    className="contact-us-info"
                    onClick={() =>
                      window.open(
                        "mailto:wy.ongarchitect@gmail.com?subject=Subject&body=Body%20goes%20here"
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      style={{ color: "grey" }}
                      className="fa fa-solid fa-envelope footer-fa fa-lg"
                    ></i>
                    <div style={{ color: "grey", margin: "0 0 0 9px" }}>
                      wy.ongarchitect@gmail.com
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Contact);
