import Masonry from "@mui/lab/Masonry";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Data from "../data/data.json";
import { ProjectItems } from "../widgets/projectsItem";
import { ProjectDetails } from "./projectDetails";

export const Projects = () => {
  const [isViewerOpen] = useState(true);
  console.log("re-render");
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        {isViewerOpen ? (
          <Tabs>
            <TabList>
              <Tab key={"all-tab"}>All</Tab>
              <Tab key={"commercial-tab"}>Commercial</Tab>
              <Tab key={"residential-tab"}>Residential</Tab>
              <Tab key={"competitions-tab"}>Competitions</Tab>
              <Tab key={"masterplan-tab"}>Masterplan</Tab>
              <Tab key={"mix-dev-tab"}>Mixed Development</Tab>
              <Tab key={"interior-tab"}>Interior</Tab>
            </TabList>
            {/* All panel */}
            <TabPanel key={"all"}>
              <div className="row">
                <div className="portfolio-items">
                  <Masonry
                    columns={{ xs: 1, md: 2, lg: 3 }}
                    spacing={2}
                    className="masonry-project"
                  >
                    {Data.map(
                      (
                        { title, thumb, location, coverCategory, tag },
                        index
                      ) => (
                        <Link
                          key={title}
                          to={{
                            pathname: `/projects/${title}`,
                          }}
                          className="portfolio-project"
                        >
                          <div>
                            <div className="portfolio-item cursor">
                              <div className="hover-bg">
                                <div className="hover-text">
                                  <h4>{title}</h4>
                                  <h5>{location}</h5>
                                  <p>{coverCategory}</p>
                                  <p>{tag}</p>
                                </div>
                                <img
                                  src={thumb}
                                  style={{ width: "100%", display: "block" }}
                                  //className="img-responsive"
                                  alt="Project Title"
                                />{" "}
                              </div>
                            </div>
                          </div>
                        </Link>
                      )
                    )}
                  </Masonry>
                </div>
              </div>
            </TabPanel>
            <TabPanel key={"commercial"}>
              <ProjectItems category="Commercial" />
            </TabPanel>
            <TabPanel key={"residential"}>
              <ProjectItems category="Residential" />
            </TabPanel>
            <TabPanel key={"competitions"}>
              <ProjectItems category="Competitions" />
            </TabPanel>
            <TabPanel key={"masterplan"}>
              <ProjectItems category="Masterplan" />
            </TabPanel>
            <TabPanel key={"mix-dev"}>
              <ProjectItems category="Mixed development" />
            </TabPanel>
            <TabPanel key={"interior"}>
              <ProjectItems category="Interior" />
            </TabPanel>
          </Tabs>
        ) : (
          <ProjectDetails />
        )}
      </div>
    </div>
  );
};
