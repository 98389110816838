const aboutData = {
  aboutTitle: "About Us",
  aboutContent:
    "WY ONG Architect is a registered Sole Proprietorship under Lembaga Arkitek Malaysia (LAM) which is located in Klang Valley. We are experienced in handling a comprehensive range of projects including residential, commercial, industrial and mixed use developments.\n\nAt WY ONG Architect, practicality and aesthetically pleasing are our utmost priorities in delivering our architectural solutions. We strive to keep ourselves abreast of the latest technologies and sustainable design innovations to fulfill your desires and satisfaction.\n\nWe are passionate and we care about your trust in us.",
  curriculumVitae: {
    aboutTitle: "Curriculum Vitae",
    principalArchitect: {
      name: "Ar. Ong Wee Yeak",
      title: "Principal Architect",
      aboutContent1: [
        "- Registered Architect, LAM",
        "- GBI Facilitator (GBIF)",
        "- Senior Lecturer, TAR UMT & UTAR",
      ],
      aboutContent2: [
        "- Corporate Member, PAM",
        "- MBAM Graduate Alumni",
        "- Certified Professional Autodesk Revit Architecture",
      ],
      academicQualifications: {
        aboutSubTitle: "Academic Qualifications",
        aboutContent: [
          "- Bachelor of Science (HBP) (Architecture), USM",
          "- Bachelor of Architecture, UPM",
          "- Master of Project Management, UM",
        ],
      },
      awards: {
        aboutSubTitle: "Awards",
        aboutContent: [
          "- Master Builders Association Malaysia (MBAM) Scholarship Award",
          "- Autodesk Malaysia Design Competition (AMDC) 2014 (Consolation Prize)",
          "- University Dean’s List Award",
        ],
      },
      imageSrc: "img/About_principal_new.jpg",
    },
  },
};
export const About = () => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="aboutTitle">{aboutData.aboutTitle}</div>
          <div className="">
            <div className="aboutContent">{aboutData.aboutContent}</div>
          </div>
          <div className="row">
            <div className="aboutTitle">
              {aboutData.curriculumVitae.aboutTitle}
            </div>
            <div
              className="col-lg-4 col-md col-sm col-xs"
              style={{ marginBottom: "15px" }}
            >
              <img
                src="img/About_principal_new.jpg"
                alt=""
                style={{
                  width: "310px",
                }}
              />
            </div>
            <div className="col-lg-8 col-md col-sm col-xs">
              <div className="aboutSubTitle" style={{ marginTop: "0px" }}>
                {aboutData.curriculumVitae.principalArchitect.name}
              </div>
              <div className="aboutSubTitle-sub">
                {aboutData.curriculumVitae.principalArchitect.title}
              </div>
              <div className="principle-content" style={{}}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {aboutData.curriculumVitae.principalArchitect.aboutContent1.map(
                    (d) => {
                      return (
                        <div
                          className="aboutContent"
                          style={{ minWidth: "300px" }}
                          key={d}
                        >
                          {d}
                        </div>
                      );
                    }
                  )}
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {aboutData.curriculumVitae.principalArchitect.aboutContent2.map(
                    (d) => {
                      return (
                        <div
                          className="aboutContent"
                          style={{ minWidth: "300px" }}
                        >
                          {d}
                        </div>
                      );
                    }
                  )}
                </div>
              </div>

              <div className="aboutSubTitle">
                {
                  aboutData.curriculumVitae.principalArchitect
                    .academicQualifications.aboutSubTitle
                }
              </div>
              <div className="aboutContent">
                {aboutData.curriculumVitae.principalArchitect.academicQualifications.aboutContent.map(
                  (d) => {
                    return (
                      <div className="aboutContent" key={d}>
                        {d}
                      </div>
                    );
                  }
                )}
              </div>
              <div className="aboutSubTitle">
                {
                  aboutData.curriculumVitae.principalArchitect.awards
                    .aboutSubTitle
                }
              </div>
              <div className="aboutContent">
                {aboutData.curriculumVitae.principalArchitect.awards.aboutContent.map(
                  (d) => {
                    return (
                      <div className="aboutContent" key={d}>
                        {d}
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
