export const Footer = () => {
  return (
    <div id="footer">
      <div className="container">
        <div className="row text-right">
          <div className="col-sm-6 col-xs-12 ">
            <a href="https://www.instagram.com/wyong.architect/">
              <i className="fa fa-instagram footer-fa"></i>
            </a>
            <a href="https://www.facebook.com/wyong.architect">
              <i className="fa fa-facebook footer-fa"></i>
            </a>
            <a
              onClick={() =>
                window.open(
                  "mailto:wy.ongarchitect@gmail.com?subject=Subject&body=Body%20goes%20here"
                )
              }
              style={{ cursor: "pointer" }}
            >
              <i className="fa fa-solid fa-envelope footer-fa"></i>
            </a>
          </div>
          <div className="col-sm-6 col-xs-12">
            &copy; 2023 WY Ong Architect. All Right Reserved. || Built by{" "}
            <a href="https://immortech.co">Immortech</a>
          </div>
        </div>
      </div>
    </div>
  );
};
