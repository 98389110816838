import { Navigation } from "./components/navigation";
import { Home } from "./components/home";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Footer } from "./components/footer";
import OurPartners from "./components/ourpartners";
import Contact from "./components/contact";
import { Projects } from "./components/projects";
import { ProjectDetails } from "./components/projectDetails";
import SmoothScroll from "smooth-scroll";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  return (
    <>
      <Router>
        <Navigation />
        <Switch>
          <Route path="/" exact component={Home} />
          {/* <Route path="/projects" exact component={Projects} /> */}
          <Route path="/about" exact component={About} />
          <Route path="/services" exact component={Services} />
          <Route path="/ourpartners" exact component={OurPartners} />
          <Route path="/projects" exact component={Projects} />
          <Route path="/projects/:id" exact component={ProjectDetails} />
          <Route path="/contact" exact component={Contact} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
};

export default App;
