import { useRef, useState } from "react";
import SwiperCore, {
  Autoplay,
  EffectCreative,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import arrowLeft from "../components/assets/chevron-left-solid.svg";
import arrowRight from "../components/assets/chevron-right-solid.svg";
import useWindowDimensions from "./useWindowDimensions";
const imgSrc = [
  "img/overlay-bg2.jpg",
  "img/overlay-bg5.jpg",
  "img/overlay-bg1.jpg",
  "img/overlay-bg4.jpg",
  "img/overlay-bg.jpg",
  "img/overlay-bg3.jpg",
];

export const Home = () => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const { width } = useWindowDimensions();
  const nexto = () => {
    swiper?.slideNext();
  };
  const prevto = () => {
    swiper?.slidePrev();
  };
  const ref = useRef<HTMLImageElement>(null);
  const buttonWidth = Math.min(75, width / 10);

  // useEffect(() => {
  //   if (ref.current) {
  //     console.log(ref.current.clientHeight);
  //   }
  // }, []);

  return (
    <div id="home" style={{}}>
      <button
        className="home-slide-button"
        onClick={prevto}
        style={{
          border: "none",
          position: "absolute",
          height: ref.current?.clientHeight,
          width: buttonWidth,
          display: "flex",
          zIndex: 1029,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img style={{ width: "100%" }} alt="arrowLeft" src={arrowLeft}></img>
      </button>
      <button
        className="home-slide-button"
        onClick={nexto}
        style={{
          border: "none",
          position: "absolute",
          height: ref.current?.clientHeight,
          width: buttonWidth,
          display: "flex",
          right: 0,
          zIndex: 1029,
          justifyContent: "center",
          alignItems: "center",
          marginRight: 0,
        }}
      >
        <img style={{ width: "100%" }} alt="arrowRight" src={arrowRight}></img>
      </button>
      <Swiper
        onSwiper={setSwiper}
        className="mySwiper"
        style={{
          width: "100%",
          aspectRatio: "7/3",
          height: "77vh",
          maxHeight: width / 2,
          // objectFit: "contain",
        }}
        // navigation={{}}
        speed={1500}
        modules={[Pagination, Scrollbar, Autoplay, EffectCreative, Navigation]}
        grabCursor={true}
        effect={"creative"}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -1],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        }}
        slidesPerView={1}
        pagination={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        allowTouchMove
        loop
      >
        {imgSrc.map((homeImg, index) => {
          return (
            <SwiperSlide key={index}>
              <img
                ref={ref}
                loading="lazy"
                src={homeImg}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
              <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};
