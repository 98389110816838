import Masonry from "@mui/lab/Masonry";
import { useEffect } from "react";
import Partners from "../data/partners.json";

const ourPartnersDetails = {
  header: "Our Partners",
  partner_title: "A & LEE CONSULT PLT",
  partner_image: "img/A&LEE CONSULT.png",
  description:
    "In pursuit of excellence and advancement, A & Lee Consult PLT continuously strive to provide quality services to our client with functional and economical design. We work closely with all consultants and clients to solve issues and provide excellent end product to end users.\n\nWe believe in building long-term relationships with all our clients underpinned by being a professional and ethical partner in all our projects.",
  services_title: "Our Services",
  services_subTitle: "Civil and Structural Engineering",
  services: [
    "Structural design of residential and commercial buildings such as bungalows, hotels, warehouse and industrial buildings",
    "Structural design of high rise buildings",
    "Planning and design of major mixed developments; urban and rural housing development infrastructural works",
    "Steel work design, temporary structure checking, shop drawings checking",
    "Site work inspection",
    "Local authority submission service",
    "Structure strengthening work design for demolition and rebuild buildings.",
  ],
};

const OurPartners = () => {
  useEffect(() => {
    console.log("Recreate");
  }, []);
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title" style={{ paddingBottom: "0px" }}>
          <div className="col">
            <div className="row">
              <div className="ourPartner-header">
                {ourPartnersDetails.header}
              </div>
            </div>
            <div className="row">
              <div className="ourPartner_title">
                {ourPartnersDetails.partner_title}
              </div>
              <div style={{ float: "left" }}>
                <img
                  src={ourPartnersDetails.partner_image}
                  alt={ourPartnersDetails.partner_title}
                  className="ourPartnerImg"
                />
              </div>
            </div>
            <div className="row" style={{ textAlign: "left" }}>
              <div className="ourPartner_description">
                {ourPartnersDetails.description}
              </div>
              <div className="ourPartner_title">
                {ourPartnersDetails.services_title}
              </div>
              <div className="ourPartner_subtitle">
                {ourPartnersDetails.services_subTitle}
              </div>
              <div className="ourPartner_description">
                <ul>
                  {ourPartnersDetails.services.map((bullet, index) => {
                    return <li>{bullet}</li>;
                  })}
                </ul>

                {/* <li>
                  Structural design of residential and commercial buildings such
                  as bungalows, hotels, warehouse and industrial buildings
                </li>
                <li>Structural design of high rise buildings</li>
                <li>
                  Planning and design of major mixed developments; urban and
                  rural housing development infrastructural works
                </li>
                <li>
                  Steel work design, temporary structure checking, shop drawings
                  checking
                </li>
                <li>Site work inspection</li>
                <li>Local authority submission service</li>
                <li>
                  Structure strengthening work design for demolition and rebuild
                  buildings.
                </li> */}
              </div>
            </div>
          </div>
        </div>
        <div className="ourPartner_title">Collaboration Projects</div>
        <div className="row">
          <div className="portfolio-items">
            <Masonry columns={{ xs: 1, md: 2, lg: 3 }} spacing={2}>
              {Partners.map(({ title, img, location, tag }, index) => (
                <div
                  key={index}
                  //onClick={() => closeImageViewer(index)}
                  //className="col-sm-6 col-md-4 col-xl-4 portfolio-individual"
                  //className="col"
                >
                  <div className="portfolio-item cursor">
                    <div className="hover-bg">
                      <div className="hover-text">
                        <h4>{title}</h4>
                        <h5>{location}</h5>
                        <p>{tag}</p>
                      </div>
                      <img
                        key={index}
                        src={img}
                        style={{ width: "100%" }}
                        //className="img-responsive"
                        alt={title}
                      />{" "}
                    </div>
                  </div>
                </div>
              ))}
            </Masonry>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurPartners;
