import Masonry from "@mui/lab/Masonry";
import { Link } from "react-router-dom";
import Data from "../data/data.json";

export const ProjectItems = (props) => {
  return (
    <div className="row">
      <div className="portfolio-items">
        <Masonry columns={{ xs: 1, md: 2, lg: 3 }} spacing={2}>
          {Data.filter((x) => x.category.includes(props.category)).map(
            ({ title, thumb, location, coverCategory, tag }, index) => (
              <Link
                key={index}
                to={{
                  pathname: `/projects/${title}`,
                }}
                className="a.navbar-brand page-scroll"
              >
                <div key={index}>
                  <div className="portfolio-item cursor">
                    <div className="hover-bg">
                      <div className="hover-text">
                        <h4>{title}</h4>
                        <h5>{location}</h5>
                        <p>{coverCategory}</p>
                        <p>{tag}</p>
                      </div>
                      <img
                        src={thumb}
                        style={{ width: "100%", display: "block" }}
                        //className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    </div>
                  </div>
                </div>
              </Link>
            )
          )}
        </Masonry>
      </div>
    </div>
  );
};
