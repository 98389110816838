import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

const active = { borderBottom: "2px solid #035397" };
const inactive = {};

export const Navigation = (props) => {
  const [selected, setSelected] = useState(0);
  const { pathname } = useLocation();
  useEffect(() => {
    console.log("Recreate navigation bar");
  }, []);
  useEffect(() => {
    navigationSelectedIndicator(pathname);
  }, [pathname]);

  function collapseHamburger() {
    if (window.innerWidth < 768) {
      document.getElementById("menuButton").click();
    }
  }

  const navigationSelectedIndicator = (pathname) => {
    switch (pathname) {
      case "/":
        setSelected(1);
        break;
      case "/about":
        setSelected(2);
        break;
      case "/services":
        setSelected(3);
        break;
      case "/projects":
        setSelected(4);
        break;
      case "/ourpartners":
        setSelected(5);
        break;
      case "/contact":
        setSelected(6);
        break;
      default:
        break;
    }
  };

  function homeTab() {
    setSelected(1);
    collapseHamburger();
  }

  function aboutTab() {
    setSelected(2);
    collapseHamburger();
  }

  function servicesTab() {
    setSelected(3);
    collapseHamburger();
  }

  function projectsTab() {
    setSelected(4);
    collapseHamburger();
  }
  function ourPartnersTab() {
    setSelected(5);
    collapseHamburger();
  }
  function contactTab() {
    setSelected(6);
    collapseHamburger();
  }
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          {" "}
          <Link to="/" className="a.navbar-brand">
            <img src="/img/Logo.png" alt="" />{" "}
          </Link>
          <button
            type="button"
            id="menuButton"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <Link
                to="/"
                className=""
                style={selected === 1 ? active : inactive}
                onClick={homeTab}
              >
                Home
              </Link>
            </li>

            <li>
              <Link
                to="/about"
                className=""
                style={selected === 2 ? active : inactive}
                onClick={aboutTab}
              >
                About
              </Link>
            </li>

            <li>
              <Link
                to="/services"
                className=""
                style={selected === 3 ? active : inactive}
                onClick={servicesTab}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="/projects"
                className=""
                style={selected === 4 ? active : inactive}
                onClick={projectsTab}
              >
                Projects
              </Link>
            </li>

            <li>
              <Link
                to="/ourpartners"
                className=""
                style={selected === 5 ? active : inactive}
                onClick={ourPartnersTab}
              >
                Our Partners
              </Link>
            </li>

            <li>
              <Link
                to="/contact"
                className="page-scroll"
                style={selected === 6 ? active : inactive}
                onClick={contactTab}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
